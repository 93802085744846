import React from 'react';
import './apidiff.scss';
import RawHTML from '../../../../../../components/RawHTML';
import Container from '../../../../../../components/Container';

const html = `<html>
<head>
<link rel="stylesheet" href="apidiff.css" type="text/css" />
<meta charset="utf-8" />
</head>
<body>

<div class="headerFile">
<div class="headerName">PDFNet/PDFNetOBJC.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status removed">Removed</span> e_insert_goto_bookmark</div>
<div class="difference"><span class="status removed">Removed</span> -[PTVerificationOptions AddTrustedCertificate:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTVerificationOptions RemoveTrustedCertificate:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTTrustVerificationResult initWithImpl:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTVerificationResult GetSignersDigestAlgorithm]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTVerificationResult initWithImpl:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTCADConvertOptions GetLineWeight]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTCADConvertOptions SetLineWeight:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTExternalAnnotManager GetLastJSON]</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> e_ptcommonName</div>
<div class="difference"><span class="status added">Added</span> e_ptsurname</div>
<div class="difference"><span class="status added">Added</span> e_ptcountryName</div>
<div class="difference"><span class="status added">Added</span> e_ptlocalityName</div>
<div class="difference"><span class="status added">Added</span> e_ptstateOrProvinceName</div>
<div class="difference"><span class="status added">Added</span> e_ptstreetAddress</div>
<div class="difference"><span class="status added">Added</span> e_ptorganizationName</div>
<div class="difference"><span class="status added">Added</span> e_ptorganizationalUnitName</div>
<div class="difference"><span class="status added">Added</span> PTPredefined</div>
<div class="difference"><span class="status added">Added</span> e_ptunsupported_digest_algorithm</div>
<div class="difference"><span class="status added">Added</span> e_ptunsupported_trust_features</div>
<div class="difference"><span class="status added">Added</span> e_ptunsupported_permissions_features</div>
<div class="difference"><span class="status added">Added</span> e_ptFullShaping</div>
<div class="difference"><span class="status added">Added</span> e_ptPartialShaping</div>
<div class="difference"><span class="status added">Added</span> e_ptNoShaping</div>
<div class="difference"><span class="status added">Added</span> PTShapingStatus</div>
<div class="difference"><span class="status added">Added</span> e_ptNoFailure</div>
<div class="difference"><span class="status added">Added</span> e_ptUnsupportedFontType</div>
<div class="difference"><span class="status added">Added</span> e_ptNotIndexEncoded</div>
<div class="difference"><span class="status added">Added</span> e_ptFontDataNotFound</div>
<div class="difference"><span class="status added">Added</span> PTShapingFailureReason</div>
<div class="difference"><span class="status added">Added</span> e_ptmn_ccitt</div>
<div class="difference"><span class="status added">Added</span> e_ptinsert_goto_bookmark</div>
<div class="difference"><span class="status added">Added</span> e_ptdoc_unsigned</div>
<div class="difference"><span class="status added">Added</span> e_ptdoc_failure</div>
<div class="difference"><span class="status added">Added</span> e_ptdoc_untrusted</div>
<div class="difference"><span class="status added">Added</span> e_ptdoc_unsupported</div>
<div class="difference"><span class="status added">Added</span> e_ptdoc_verified</div>
<div class="difference"><span class="status added">Added</span> PTSignaturesVerificationStatus</div>
<div class="difference"><span class="status added">Added</span> e_ptno_watermarks</div>
<div class="difference"><span class="status added">Added</span> e_ptraw_text_search</div>
<div class="difference"><span class="status added">Added</span> PTByteRange</div>
<div class="difference"><span class="status added">Added</span> -[PTByteRange getCptr]</div>
<div class="difference"><span class="status added">Added</span> -[PTByteRange initWithCptr:]</div>
<div class="difference"><span class="status added">Added</span> -[PTByteRange setSwigCMemOwn:]</div>
<div class="difference"><span class="status added">Added</span> -[PTByteRange dealloc]</div>
<div class="difference"><span class="status added">Added</span> -[PTByteRange GetStartOffset]</div>
<div class="difference"><span class="status added">Added</span> -[PTByteRange GetEndOffset]</div>
<div class="difference"><span class="status added">Added</span> -[PTByteRange GetSize]</div>
<div class="difference"><span class="status added">Added</span> -[PTByteRange init]</div>
<div class="difference"><span class="status added">Added</span> -[PTMatrix2D PreTranslate:v:]</div>
<div class="difference"><span class="status added">Added</span> -[PTMatrix2D PostTranslate:v:]</div>
<div class="difference"><span class="status added">Added</span> PTObjectIdentifier</div>
<div class="difference"><span class="status added">Added</span> -[PTObjectIdentifier getCptr]</div>
<div class="difference"><span class="status added">Added</span> -[PTObjectIdentifier initWithCptr:]</div>
<div class="difference"><span class="status added">Added</span> -[PTObjectIdentifier setSwigCMemOwn:]</div>
<div class="difference"><span class="status added">Added</span> -[PTObjectIdentifier dealloc]</div>
<div class="difference"><span class="status added">Added</span> -[PTObjectIdentifier Destroy]</div>
<div class="difference"><span class="status added">Added</span> -[PTObjectIdentifier GetRawValue]</div>
<div class="difference"><span class="status added">Added</span> -[PTObjectIdentifier initWithIn_oid_enum:]</div>
<div class="difference"><span class="status added">Added</span> PTX501AttributeTypeAndValue</div>
<div class="difference"><span class="status added">Added</span> -[PTX501AttributeTypeAndValue getCptr]</div>
<div class="difference"><span class="status added">Added</span> -[PTX501AttributeTypeAndValue initWithCptr:]</div>
<div class="difference"><span class="status added">Added</span> -[PTX501AttributeTypeAndValue setSwigCMemOwn:]</div>
<div class="difference"><span class="status added">Added</span> -[PTX501AttributeTypeAndValue dealloc]</div>
<div class="difference"><span class="status added">Added</span> -[PTX501AttributeTypeAndValue Destroy]</div>
<div class="difference"><span class="status added">Added</span> -[PTX501AttributeTypeAndValue GetAttributeTypeOID]</div>
<div class="difference"><span class="status added">Added</span> -[PTX501AttributeTypeAndValue GetStringValue]</div>
<div class="difference"><span class="status added">Added</span> -[PTX501AttributeTypeAndValue init]</div>
<div class="difference"><span class="status added">Added</span> PTX501DistinguishedName</div>
<div class="difference"><span class="status added">Added</span> -[PTX501DistinguishedName getCptr]</div>
<div class="difference"><span class="status added">Added</span> -[PTX501DistinguishedName initWithCptr:]</div>
<div class="difference"><span class="status added">Added</span> -[PTX501DistinguishedName setSwigCMemOwn:]</div>
<div class="difference"><span class="status added">Added</span> -[PTX501DistinguishedName dealloc]</div>
<div class="difference"><span class="status added">Added</span> -[PTX501DistinguishedName Destroy]</div>
<div class="difference"><span class="status added">Added</span> -[PTX501DistinguishedName HasAttribute:]</div>
<div class="difference"><span class="status added">Added</span> -[PTX501DistinguishedName GetStringValuesForAttribute:]</div>
<div class="difference"><span class="status added">Added</span> -[PTX501DistinguishedName GetAllAttributesAndValues]</div>
<div class="difference"><span class="status added">Added</span> -[PTX501DistinguishedName init]</div>
<div class="difference"><span class="status added">Added</span> PTX509Extension</div>
<div class="difference"><span class="status added">Added</span> -[PTX509Extension getCptr]</div>
<div class="difference"><span class="status added">Added</span> -[PTX509Extension initWithCptr:]</div>
<div class="difference"><span class="status added">Added</span> -[PTX509Extension setSwigCMemOwn:]</div>
<div class="difference"><span class="status added">Added</span> -[PTX509Extension dealloc]</div>
<div class="difference"><span class="status added">Added</span> -[PTX509Extension Destroy]</div>
<div class="difference"><span class="status added">Added</span> -[PTX509Extension IsCritical]</div>
<div class="difference"><span class="status added">Added</span> -[PTX509Extension GetObjectIdentifier]</div>
<div class="difference"><span class="status added">Added</span> -[PTX509Extension ToString]</div>
<div class="difference"><span class="status added">Added</span> -[PTX509Extension GetData]</div>
<div class="difference"><span class="status added">Added</span> -[PTX509Extension init]</div>
<div class="difference"><span class="status added">Added</span> PTX509Certificate</div>
<div class="difference"><span class="status added">Added</span> -[PTX509Certificate getCptr]</div>
<div class="difference"><span class="status added">Added</span> -[PTX509Certificate initWithCptr:]</div>
<div class="difference"><span class="status added">Added</span> -[PTX509Certificate setSwigCMemOwn:]</div>
<div class="difference"><span class="status added">Added</span> -[PTX509Certificate dealloc]</div>
<div class="difference"><span class="status added">Added</span> -[PTX509Certificate Destroy]</div>
<div class="difference"><span class="status added">Added</span> -[PTX509Certificate GetIssuerField]</div>
<div class="difference"><span class="status added">Added</span> -[PTX509Certificate GetSubjectField]</div>
<div class="difference"><span class="status added">Added</span> -[PTX509Certificate GetNotBeforeEpochTime]</div>
<div class="difference"><span class="status added">Added</span> -[PTX509Certificate GetNotAfterEpochTime]</div>
<div class="difference"><span class="status added">Added</span> -[PTX509Certificate GetRawX509VersionNumber]</div>
<div class="difference"><span class="status added">Added</span> -[PTX509Certificate ToString]</div>
<div class="difference"><span class="status added">Added</span> -[PTX509Certificate GetFingerprint:]</div>
<div class="difference"><span class="status added">Added</span> -[PTX509Certificate GetSerialNumber]</div>
<div class="difference"><span class="status added">Added</span> -[PTX509Certificate GetExtensions]</div>
<div class="difference"><span class="status added">Added</span> -[PTX509Certificate GetData]</div>
<div class="difference"><span class="status added">Added</span> -[PTX509Certificate init]</div>
<div class="difference"><span class="status added">Added</span> -[PTVerificationOptions AddTrustedCertificateWithBuf:in_buf_size:]</div>
<div class="difference"><span class="status added">Added</span> -[PTVerificationOptions AddTrustedCertificateWithFilePath:]</div>
<div class="difference"><span class="status added">Added</span> -[PTVerificationOptions AddTrustedCertificates:in_size:]</div>
<div class="difference"><span class="status added">Added</span> -[PTVerificationOptions EnableOnlineCRLRevocationChecking:]</div>
<div class="difference"><span class="status added">Added</span> -[PTVerificationOptions EnableOnlineOCSPRevocationChecking:]</div>
<div class="difference"><span class="status added">Added</span> -[PTVerificationOptions EnableOnlineRevocationChecking:]</div>
<div class="difference"><span class="status added">Added</span> -[PTTrustVerificationResult HasEmbeddedTimestampVerificationResult]</div>
<div class="difference"><span class="status added">Added</span> -[PTTrustVerificationResult GetEmbeddedTimestampVerificationResult]</div>
<div class="difference"><span class="status added">Added</span> -[PTTrustVerificationResult GetCertPath]</div>
<div class="difference"><span class="status added">Added</span> -[PTTrustVerificationResult init]</div>
<div class="difference"><span class="status added">Added</span> -[PTVerificationResult GetDigestAlgorithm]</div>
<div class="difference"><span class="status added">Added</span> -[PTVerificationResult GetDocumentStatusAsString]</div>
<div class="difference"><span class="status added">Added</span> -[PTVerificationResult GetDigestStatusAsString]</div>
<div class="difference"><span class="status added">Added</span> -[PTVerificationResult GetTrustStatusAsString]</div>
<div class="difference"><span class="status added">Added</span> -[PTVerificationResult GetPermissionsStatusAsString]</div>
<div class="difference"><span class="status added">Added</span> -[PTVerificationResult GetUnsupportedFeatures]</div>
<div class="difference"><span class="status added">Added</span> -[PTVerificationResult init]</div>
<div class="difference"><span class="status added">Added</span> PTEmbeddedTimestampVerificationResult</div>
<div class="difference"><span class="status added">Added</span> -[PTEmbeddedTimestampVerificationResult getCptr]</div>
<div class="difference"><span class="status added">Added</span> -[PTEmbeddedTimestampVerificationResult initWithCptr:]</div>
<div class="difference"><span class="status added">Added</span> -[PTEmbeddedTimestampVerificationResult setSwigCMemOwn:]</div>
<div class="difference"><span class="status added">Added</span> -[PTEmbeddedTimestampVerificationResult dealloc]</div>
<div class="difference"><span class="status added">Added</span> -[PTEmbeddedTimestampVerificationResult Destroy]</div>
<div class="difference"><span class="status added">Added</span> -[PTEmbeddedTimestampVerificationResult GetVerificationStatus]</div>
<div class="difference"><span class="status added">Added</span> -[PTEmbeddedTimestampVerificationResult GetCMSDigestStatus]</div>
<div class="difference"><span class="status added">Added</span> -[PTEmbeddedTimestampVerificationResult GetMessageImprintDigestStatus]</div>
<div class="difference"><span class="status added">Added</span> -[PTEmbeddedTimestampVerificationResult GetTrustStatus]</div>
<div class="difference"><span class="status added">Added</span> -[PTEmbeddedTimestampVerificationResult GetCMSDigestStatusAsString]</div>
<div class="difference"><span class="status added">Added</span> -[PTEmbeddedTimestampVerificationResult GetMessageImprintDigestStatusAsString]</div>
<div class="difference"><span class="status added">Added</span> -[PTEmbeddedTimestampVerificationResult GetTrustStatusAsString]</div>
<div class="difference"><span class="status added">Added</span> -[PTEmbeddedTimestampVerificationResult HasTrustVerificationResult]</div>
<div class="difference"><span class="status added">Added</span> -[PTEmbeddedTimestampVerificationResult GetTrustVerificationResult]</div>
<div class="difference"><span class="status added">Added</span> -[PTEmbeddedTimestampVerificationResult GetCMSSignatureDigestAlgorithm]</div>
<div class="difference"><span class="status added">Added</span> -[PTEmbeddedTimestampVerificationResult GetMessageImprintDigestAlgorithm]</div>
<div class="difference"><span class="status added">Added</span> -[PTEmbeddedTimestampVerificationResult GetUnsupportedFeatures]</div>
<div class="difference"><span class="status added">Added</span> -[PTEmbeddedTimestampVerificationResult init]</div>
<div class="difference"><span class="status added">Added</span> PTTimestampingTestResult</div>
<div class="difference"><span class="status added">Added</span> -[PTTimestampingTestResult getCptr]</div>
<div class="difference"><span class="status added">Added</span> -[PTTimestampingTestResult initWithCptr:]</div>
<div class="difference"><span class="status added">Added</span> -[PTTimestampingTestResult setSwigCMemOwn:]</div>
<div class="difference"><span class="status added">Added</span> -[PTTimestampingTestResult dealloc]</div>
<div class="difference"><span class="status added">Added</span> -[PTTimestampingTestResult Destroy]</div>
<div class="difference"><span class="status added">Added</span> -[PTTimestampingTestResult GetStatus]</div>
<div class="difference"><span class="status added">Added</span> -[PTTimestampingTestResult GetString]</div>
<div class="difference"><span class="status added">Added</span> -[PTTimestampingTestResult HasResponseVerificationResult]</div>
<div class="difference"><span class="status added">Added</span> -[PTTimestampingTestResult GetResponseVerificationResult]</div>
<div class="difference"><span class="status added">Added</span> -[PTTimestampingTestResult init]</div>
<div class="difference"><span class="status added">Added</span> PTTimestampingConfiguration</div>
<div class="difference"><span class="status added">Added</span> -[PTTimestampingConfiguration getCptr]</div>
<div class="difference"><span class="status added">Added</span> -[PTTimestampingConfiguration initWithCptr:]</div>
<div class="difference"><span class="status added">Added</span> -[PTTimestampingConfiguration setSwigCMemOwn:]</div>
<div class="difference"><span class="status added">Added</span> -[PTTimestampingConfiguration dealloc]</div>
<div class="difference"><span class="status added">Added</span> -[PTTimestampingConfiguration Destroy]</div>
<div class="difference"><span class="status added">Added</span> -[PTTimestampingConfiguration SetTimestampAuthorityServerURL:]</div>
<div class="difference"><span class="status added">Added</span> -[PTTimestampingConfiguration SetTimestampAuthorityServerUsername:]</div>
<div class="difference"><span class="status added">Added</span> -[PTTimestampingConfiguration SetTimestampAuthorityServerPassword:]</div>
<div class="difference"><span class="status added">Added</span> -[PTTimestampingConfiguration SetUseNonce:]</div>
<div class="difference"><span class="status added">Added</span> -[PTTimestampingConfiguration TestConfiguration:]</div>
<div class="difference"><span class="status added">Added</span> -[PTTimestampingConfiguration init]</div>
<div class="difference"><span class="status added">Added</span> -[PTTimestampingConfiguration initWithIn_url:]</div>
<div class="difference"><span class="status added">Added</span> -[PTDigitalSignatureField IsCertification]</div>
<div class="difference"><span class="status added">Added</span> -[PTDigitalSignatureField GetSignerCertFromCMS]</div>
<div class="difference"><span class="status added">Added</span> -[PTDigitalSignatureField GetByteRanges]</div>
<div class="difference"><span class="status added">Added</span> -[PTDigitalSignatureField GetCertPathsFromCMS]</div>
<div class="difference"><span class="status added">Added</span> -[PTDigitalSignatureField EnableLTVOfflineVerification:]</div>
<div class="difference"><span class="status added">Added</span> -[PTDigitalSignatureField TimestampOnNextSave:in_timestamp_response_verification_options:]</div>
<div class="difference"><span class="status added">Added</span> PTRefreshOptions</div>
<div class="difference"><span class="status added">Added</span> -[PTRefreshOptions getCptr]</div>
<div class="difference"><span class="status added">Added</span> -[PTRefreshOptions initWithCptr:]</div>
<div class="difference"><span class="status added">Added</span> -[PTRefreshOptions setSwigCMemOwn:]</div>
<div class="difference"><span class="status added">Added</span> -[PTRefreshOptions dealloc]</div>
<div class="difference"><span class="status added">Added</span> -[PTRefreshOptions GetDrawBackgroundOnly]</div>
<div class="difference"><span class="status added">Added</span> -[PTRefreshOptions SetDrawBackgroundOnly:]</div>
<div class="difference"><span class="status added">Added</span> -[PTRefreshOptions GetRefreshExisting]</div>
<div class="difference"><span class="status added">Added</span> -[PTRefreshOptions SetRefreshExisting:]</div>
<div class="difference"><span class="status added">Added</span> -[PTRefreshOptions GetUseNonStandardRotation]</div>
<div class="difference"><span class="status added">Added</span> -[PTRefreshOptions SetUseNonStandardRotation:]</div>
<div class="difference"><span class="status added">Added</span> -[PTRefreshOptions GetUseRoundedCorners]</div>
<div class="difference"><span class="status added">Added</span> -[PTRefreshOptions SetUseRoundedCorners:]</div>
<div class="difference"><span class="status added">Added</span> -[PTRefreshOptions init]</div>
<div class="difference"><span class="status added">Added</span> -[PTAnnot RefreshAppearanceWithOptions:]</div>
<div class="difference"><span class="status added">Added</span> -[PTCADConvertOptions GetAllowThinLines]</div>
<div class="difference"><span class="status added">Added</span> -[PTCADConvertOptions SetAllowThinLines:]</div>
<div class="difference"><span class="status added">Added</span> -[PTCADConvertOptions GetBackgroundColor]</div>
<div class="difference"><span class="status added">Added</span> -[PTCADConvertOptions SetBackgroundColor:]</div>
<div class="difference"><span class="status added">Added</span> -[PTCADConvertOptions SetColorMode:]</div>
<div class="difference"><span class="status added">Added</span> +[PTRubberStamp CreateCustom:pos:form_xobject:]</div>
<div class="difference"><span class="status added">Added</span> +[PTPDFACompliance GetDeclaredConformance:]</div>
<div class="difference"><span class="status added">Added</span> PTShapedText</div>
<div class="difference"><span class="status added">Added</span> -[PTShapedText getCptr]</div>
<div class="difference"><span class="status added">Added</span> -[PTShapedText initWithCptr:]</div>
<div class="difference"><span class="status added">Added</span> -[PTShapedText setSwigCMemOwn:]</div>
<div class="difference"><span class="status added">Added</span> -[PTShapedText dealloc]</div>
<div class="difference"><span class="status added">Added</span> -[PTShapedText Destroy]</div>
<div class="difference"><span class="status added">Added</span> -[PTShapedText GetScale]</div>
<div class="difference"><span class="status added">Added</span> -[PTShapedText GetShapingStatus]</div>
<div class="difference"><span class="status added">Added</span> -[PTShapedText GetFailureReason]</div>
<div class="difference"><span class="status added">Added</span> -[PTShapedText GetText]</div>
<div class="difference"><span class="status added">Added</span> -[PTShapedText GetNumGlyphs]</div>
<div class="difference"><span class="status added">Added</span> -[PTShapedText GetGlyph:]</div>
<div class="difference"><span class="status added">Added</span> -[PTShapedText GetGlyphXPos:]</div>
<div class="difference"><span class="status added">Added</span> -[PTShapedText GetGlyphYPos:]</div>
<div class="difference"><span class="status added">Added</span> -[PTShapedText init]</div>
<div class="difference"><span class="status added">Added</span> -[PTFont GetShapedText:]</div>
<div class="difference"><span class="status added">Added</span> -[PTElementBuilder CreateShapedTextRun:]</div>
<div class="difference"><span class="status added">Added</span> -[PTPDFDoc RefreshAnnotAppearances:]</div>
<div class="difference"><span class="status added">Added</span> -[PTPDFDoc VerifySignedDigitalSignatures:]</div>
<div class="difference"><span class="status added">Added</span> -[PTOfficeToPDFOptions SetAutoFillDict:]</div>
<div class="difference"><span class="status added">Added</span> -[PTOfficeToPDFOptions GetApplyPageBreaksToSheet]</div>
<div class="difference"><span class="status added">Added</span> -[PTOfficeToPDFOptions SetApplyPageBreaksToSheet]</div>
<div class="difference"><span class="status added">Added</span> +[PTConvert PageToHtml:]</div>
<div class="difference"><span class="status added">Added</span> -[PTTextExtractor SetOCGContext:]</div>
<div class="difference"><span class="status added">Added</span> -[PTTextSearch SetOCGContext:]</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> -[PTUInt32Iterator Current]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (SWIGTYPE_p_unsigned_int *)Current</td></tr>
<tr><th>To</th><td class="declaration">- (unsigned int)Current</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTSDFDocSnapshot initWithImpl:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (instancetype)initWithImpl:(SWIGTYPE_TRN_DocSnapshot *)impl</td></tr>
<tr><th>To</th><td class="declaration">- (instancetype)initWithImpl:(void *)impl</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTSDFResultSnapshot initWithImpl:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (instancetype)initWithImpl:(SWIGTYPE_TRN_ResultSnapshot *)impl</td></tr>
<tr><th>To</th><td class="declaration">- (instancetype)initWithImpl:(void *)impl</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTSDFUndoManager initWithImpl:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (instancetype)initWithImpl:(SWIGTYPE_TRN_UndoManager *)impl</td></tr>
<tr><th>To</th><td class="declaration">- (instancetype)initWithImpl:(void *)impl</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTVerificationOptions initWithImpl:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (instancetype)initWithImpl:(SWIGTYPE_TRN_VerificationOptions *)impl</td></tr>
<tr><th>To</th><td class="declaration">- (instancetype)initWithImpl:(void *)impl</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFDoc GetDownloadedByteCount]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (int)GetDownloadedByteCount</td></tr>
<tr><th>To</th><td class="declaration">- (unsigned long long)GetDownloadedByteCount</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFDoc GetTotalRemoteByteCount]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (int)GetTotalRemoteByteCount</td></tr>
<tr><th>To</th><td class="declaration">- (unsigned long long)GetTotalRemoteByteCount</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFView GetVisiblePages]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (NSMutableArray *)GetVisiblePages</td></tr>
<tr><th>To</th><td class="declaration">- (NSArray&lt;NSNumber *&gt; *)GetVisiblePages</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTPDFView GetThumbInCache:buf:out_width:out_height:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (BOOL)GetThumbInCache:(int)page_num buf:(NSData *)buf out_width:(SWIGTYPE_p_unsigned_int *)out_width out_height:(SWIGTYPE_p_unsigned_int *)out_height</td></tr>
<tr><th>To</th><td class="declaration">- (BOOL)GetThumbInCache:(int)page_num buf:(NSData *)buf out_width:(unsigned int)out_width out_height:(unsigned int)out_height</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTTextExtractorStyle GetColor]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (NSMutableArray *)GetColor</td></tr>
<tr><th>To</th><td class="declaration">- (NSArray&lt;NSNumber *&gt; *)GetColor</td></tr>
</table>
<br>
</div>

</div>

<div class="headerFile">
<div class="headerName">PDFNet/PDFViewCtrl.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTPDFViewCtrl.pagingScrollView</div>
<div class="difference"><span class="status added">Added</span> PTPDFViewCtrl.contentScrollView</div>
</div>

</div>



<div class="headerFile">
<div class="headerName">Tools/PTAddPagesManager.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> -[PTAddPagesManager showCamera]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTAddPagesViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTAddPagesViewController.addCameraImagePageButtonItem</div>
<div class="difference"><span class="status added">Added</span> -[PTAddPagesViewController showCamera:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTAnnotationStylePresetsGroup.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTAnnotationStylePresetsGroup</div>
<div class="difference"><span class="status added">Added</span> -[PTAnnotationStylePresetsGroup initWithStyles:]</div>
<div class="difference"><span class="status added">Added</span> -[PTAnnotationStylePresetsGroup initWithCoder:]</div>
<div class="difference"><span class="status added">Added</span> PTAnnotationStylePresetsGroup.styles</div>
<div class="difference"><span class="status added">Added</span> PTAnnotationStylePresetsGroup.selectedIndex</div>
<div class="difference"><span class="status added">Added</span> PTAnnotationStylePresetsGroup.selectedStyle</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTAnnotationStylePresetsView.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTAnnotationStylePresetsViewDelegate</div>
<div class="difference"><span class="status added">Added</span> -[PTAnnotationStylePresetsViewDelegate presetsView:editPresetForStyle:fromView:]</div>
<div class="difference"><span class="status added">Added</span> PTAnnotationStylePresetsView</div>
<div class="difference"><span class="status added">Added</span> PTAnnotationStylePresetsView.presets</div>
<div class="difference"><span class="status added">Added</span> PTAnnotationStylePresetsView.contentView</div>
<div class="difference"><span class="status added">Added</span> PTAnnotationStylePresetsView.delegate</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTAnnotStyle.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> -[PTAnnotStyle initWithCoder:]</div>
<div class="difference"><span class="status added">Added</span> -[PTAnnotStyle applyToAnnotation:]</div>
<div class="difference"><span class="status added">Added</span> -[PTAnnotStyle applyToAnnotation:doc:]</div>
<div class="difference"><span class="status added">Added</span> -[PTAnnotStyle isEqualToAnnotStyle:]</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> PTAnnotStyle</div>
<table>
<tr><th></th><th>Protocols</th></tr>
<tr><th>From</th><td><em>none</em></td></tr>
<tr><th>To</th><td>NSCoding, NSCopying</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTAnnotStyle initWithAnnot:onPDFDoc:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (nonnull instancetype)initWithAnnot:(nonnull PTAnnot *)annot onPDFDoc:(nonnull PTPDFDoc *)pdfDoc</td></tr>
<tr><th>To</th><td class="declaration">- (nonnull instancetype)initWithAnnot:(nonnull PTAnnot *)annot onPDFDoc:(nullable PTPDFDoc *)pdfDoc</td></tr>
</table>
<br>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTAnnotStyleToolbar.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTAnnotStyleToolbar</div>
<div class="difference"><span class="status added">Added</span> -[PTAnnotStyleToolbar initWithToolManager:]</div>
<div class="difference"><span class="status added">Added</span> PTAnnotStyleToolbar.toolManager</div>
<div class="difference"><span class="status added">Added</span> PTAnnotStyleToolbar.annotStylePresets</div>
<div class="difference"><span class="status added">Added</span> PTAnnotStyleToolbar.stylePicker</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTBookmarkManager.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> -[PTBookmarkManager bookmarksForDoc:rootPDFBookmark:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (nonnull NSArray&lt;PTUserBookmark *&gt; *)bookmarksForDoc:(nonnull PTPDFDoc *)doc rootPDFBookmark:(nonnull PTBookmark *)rootPDFBookmark</td></tr>
<tr><th>To</th><td class="declaration">- (nonnull NSArray&lt;PTUserBookmark *&gt; *)bookmarksForDoc:(nonnull PTPDFDoc *)doc rootPDFBookmark:(nullable PTBookmark *)rootPDFBookmark</td></tr>
</table>
<br>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTColorDefaults.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> +[PTColorDefaults setDefaultFreeTextFontName:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">+ (void)setDefaultFreeTextFontName:(nonnull NSString *)fontName</td></tr>
<tr><th>To</th><td class="declaration">+ (void)setDefaultFreeTextFontName:(nullable NSString *)fontName</td></tr>
</table>
<br>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTColorPickerViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> -[PTColorPickerViewController initWithTransitionStyle:navigationOrientation:options:colors:]</div>
<div class="difference"><span class="status added">Added</span> PTColorPickerViewController.colors</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTCreateToolBase.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTCreateToolBase.undoManagerEnabled</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTCustomStampOption.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTCustomStampOption</div>
<div class="difference"><span class="status added">Added</span> PTCustomStampOption.text</div>
<div class="difference"><span class="status added">Added</span> PTCustomStampOption.secondText</div>
<div class="difference"><span class="status added">Added</span> PTCustomStampOption.bgColorStart</div>
<div class="difference"><span class="status added">Added</span> PTCustomStampOption.bgColorEnd</div>
<div class="difference"><span class="status added">Added</span> PTCustomStampOption.textColor</div>
<div class="difference"><span class="status added">Added</span> PTCustomStampOption.borderColor</div>
<div class="difference"><span class="status added">Added</span> PTCustomStampOption.fillOpacity</div>
<div class="difference"><span class="status added">Added</span> PTCustomStampOption.pointingLeft</div>
<div class="difference"><span class="status added">Added</span> PTCustomStampOption.pointingRight</div>
<div class="difference"><span class="status added">Added</span> -[PTCustomStampOption initWithText:secondText:bgColorStart:bgColorEnd:textColor:borderColor:fillOpacity:pointingLeft:pointingRight:]</div>
<div class="difference"><span class="status added">Added</span> -[PTCustomStampOption initWithFormXObject:]</div>
<div class="difference"><span class="status added">Added</span> -[PTCustomStampOption configureStampObject:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTDocumentBaseViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTDocumentBaseViewController</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentBaseViewController init]</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentBaseViewController openDocumentWithURL:]</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentBaseViewController openDocumentWithURL:password:]</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentBaseViewController openDocumentWithPDFDoc:]</div>
<div class="difference"><span class="status added">Added</span> PTDocumentBaseViewController.httpRequestOptions</div>
<div class="difference"><span class="status added">Added</span> PTDocumentBaseViewController.additionalHTTPHeaders</div>
<div class="difference"><span class="status added">Added</span> PTDocumentBaseViewController.conversionOptions</div>
<div class="difference"><span class="status added">Added</span> PTDocumentBaseViewController.document</div>
<div class="difference"><span class="status added">Added</span> PTDocumentBaseViewController.coordinatedDocument</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentBaseViewController saveDocument:completionHandler:]</div>
<div class="difference"><span class="status added">Added</span> PTDocumentBaseViewController.pdfViewCtrl</div>
<div class="difference"><span class="status added">Added</span> PTDocumentBaseViewController.toolManager</div>
<div class="difference"><span class="status added">Added</span> PTDocumentBaseViewController.automaticallySavesDocument</div>
<div class="difference"><span class="status added">Added</span> PTDocumentBaseViewController.automaticDocumentSavingInterval</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentBaseViewController restartAutomaticDocumentSavingTimer]</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentBaseViewController restartAutomaticDocumentSavingTimerWithInterval:]</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentBaseViewController stopAutomaticDocumentSavingTimer]</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentBaseViewController closeDocumentWithCompletionHandler:]</div>
<div class="difference"><span class="status added">Added</span> PTDocumentBaseViewController.tabbedDocumentViewController</div>
<div class="difference"><span class="status added">Added</span> PTDocumentBaseViewController.documentTabItem</div>
<div class="difference"><span class="status added">Added</span> PTDocumentBaseViewController.changesPageOnTap</div>
<div class="difference"><span class="status added">Added</span> PTDocumentBaseViewController.nightModeEnabled</div>
<div class="difference"><span class="status added">Added</span> PTDocumentBaseViewController.bottomToolbarEnabled</div>
<div class="difference"><span class="status added">Added</span> PTDocumentBaseViewController.thumbnailSliderEnabled</div>
<div class="difference"><span class="status added">Added</span> PTDocumentBaseViewController.pageIndicatorEnabled</div>
<div class="difference"><span class="status added">Added</span> PTDocumentBaseViewController.pageIndicatorShowsOnPageChange</div>
<div class="difference"><span class="status added">Added</span> PTDocumentBaseViewController.pageIndicatorShowsWithControls</div>
<div class="difference"><span class="status added">Added</span> PTDocumentBaseViewController.annotationListHidden</div>
<div class="difference"><span class="status added">Added</span> PTDocumentBaseViewController.outlineListHidden</div>
<div class="difference"><span class="status added">Added</span> PTDocumentBaseViewController.bookmarkListHidden</div>
<div class="difference"><span class="status added">Added</span> PTDocumentBaseViewController.pdfLayerListHidden</div>
<div class="difference"><span class="status added">Added</span> PTDocumentBaseViewController.readerModeButtonItem</div>
<div class="difference"><span class="status added">Added</span> PTDocumentBaseViewController.searchButtonItem</div>
<div class="difference"><span class="status added">Added</span> PTDocumentBaseViewController.exportButtonItem</div>
<div class="difference"><span class="status added">Added</span> PTDocumentBaseViewController.shareButtonItem</div>
<div class="difference"><span class="status added">Added</span> PTDocumentBaseViewController.settingsButtonItem</div>
<div class="difference"><span class="status added">Added</span> PTDocumentBaseViewController.thumbnailsButtonItem</div>
<div class="difference"><span class="status added">Added</span> PTDocumentBaseViewController.navigationListsButtonItem</div>
<div class="difference"><span class="status added">Added</span> PTDocumentBaseViewController.moreItemsButtonItem</div>
<div class="difference"><span class="status added">Added</span> PTDocumentBaseViewController.addPagesButtonItem</div>
<div class="difference"><span class="status added">Added</span> PTDocumentBaseViewController.readerModeButtonHidden</div>
<div class="difference"><span class="status added">Added</span> PTDocumentBaseViewController.viewerSettingsButtonHidden</div>
<div class="difference"><span class="status added">Added</span> PTDocumentBaseViewController.shareButtonHidden</div>
<div class="difference"><span class="status added">Added</span> PTDocumentBaseViewController.searchButtonHidden</div>
<div class="difference"><span class="status added">Added</span> PTDocumentBaseViewController.exportButtonHidden</div>
<div class="difference"><span class="status added">Added</span> PTDocumentBaseViewController.moreItemsButtonHidden</div>
<div class="difference"><span class="status added">Added</span> PTDocumentBaseViewController.addPagesButtonHidden</div>
<div class="difference"><span class="status added">Added</span> PTDocumentBaseViewController.thumbnailBrowserButtonHidden</div>
<div class="difference"><span class="status added">Added</span> PTDocumentBaseViewController.navigationListsButtonHidden</div>
<div class="difference"><span class="status added">Added</span> PTDocumentBaseViewController.toolbarItems</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentBaseViewController toolbarItemsForSizeClass:]</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentBaseViewController setToolbarItems:forSizeClass:animated:]</div>
<div class="difference"><span class="status added">Added</span> PTDocumentBaseViewController.moreItems</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentBaseViewController moreItemsForSizeClass:]</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentBaseViewController setMoreItems:forSizeClass:]</div>
<div class="difference"><span class="status added">Added</span> PTDocumentBaseViewController.activityViewController</div>
<div class="difference"><span class="status added">Added</span> PTDocumentBaseViewController.navigationListsViewController</div>
<div class="difference"><span class="status added">Added</span> PTDocumentBaseViewController.alwaysShowNavigationListsAsModal</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentBaseViewController showNavigationLists]</div>
<div class="difference"><span class="status added">Added</span> PTDocumentBaseViewController.thumbnailsViewController</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentBaseViewController showThumbnailsController]</div>
<div class="difference"><span class="status added">Added</span> PTDocumentBaseViewController.textSearchViewController</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentBaseViewController showSearchViewController]</div>
<div class="difference"><span class="status added">Added</span> PTDocumentBaseViewController.thumbnailSliderController</div>
<div class="difference"><span class="status added">Added</span> PTDocumentBaseViewController.pageIndicatorViewController</div>
<div class="difference"><span class="status added">Added</span> PTDocumentBaseViewController.settingsViewController</div>
<div class="difference"><span class="status added">Added</span> PTDocumentBaseViewController.reflowViewController</div>
<div class="difference"><span class="status added">Added</span> PTDocumentBaseViewController.moreItemsViewController</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentBaseViewController showMoreItems:]</div>
<div class="difference"><span class="status added">Added</span> PTDocumentBaseViewController.addPagesViewController</div>
<div class="difference"><span class="status added">Added</span> PTDocumentBaseViewController.controlsHidden</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentBaseViewController setControlsHidden:animated:]</div>
<div class="difference"><span class="status added">Added</span> PTDocumentBaseViewController.searchViewControllerHidden</div>
<div class="difference"><span class="status added">Added</span> PTDocumentBaseViewController.thumbnailSliderHidden</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentBaseViewController setThumbnailSliderHidden:animated:]</div>
<div class="difference"><span class="status added">Added</span> PTDocumentBaseViewController.pageIndicatorHidden</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentBaseViewController setPageIndicatorHidden:animated:]</div>
<div class="difference"><span class="status added">Added</span> PTDocumentBaseViewController.reflowHidden</div>
<div class="difference"><span class="status added">Added</span> PTDocumentBaseViewController.hidesControlsOnTap</div>
<div class="difference"><span class="status added">Added</span> PTDocumentBaseViewController.automaticallyHidesControls</div>
<div class="difference"><span class="status added">Added</span> PTDocumentBaseViewController.automaticallyHideToolbars</div>
<div class="difference"><span class="status added">Added</span> PTDocumentBaseViewController.automaticControlHidingDelay</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentBaseViewController restartAutomaticControlHidingTimer]</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentBaseViewController restartAutomaticControlHidingTimerWithDelay:]</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentBaseViewController stopAutomaticControlHidingTimer]</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentBaseViewController shouldHideControlsFromTimer:]</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentBaseViewController initWithCoder:]</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentBaseViewController didOpenDocument]</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentBaseViewController handleDocumentOpeningFailureWithError:]</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentBaseViewController didBecomeInvalid]</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentBaseViewController shouldExportCachedDocumentAtURL:]</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentBaseViewController destinationURLforDocumentAtURL:]</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentBaseViewController shouldDeleteCachedDocumentAtURL:]</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentBaseViewController shouldHideControls]</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentBaseViewController shouldShowControls]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTDocumentController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTDocumentControllerDelegate</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentControllerDelegate documentControllerDidBecomeInvalid:]</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentControllerDelegate documentControllerDidOpenDocument:]</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentControllerDelegate documentController:didFailToOpenDocumentWithError:]</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentControllerDelegate documentController:shouldExportCachedDocumentAtURL:]</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentControllerDelegate documentController:destinationURLForDocumentAtURL:]</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentControllerDelegate documentController:shouldDeleteCachedDocumentAtURL:]</div>
<div class="difference"><span class="status added">Added</span> PTDocumentController</div>
<div class="difference"><span class="status added">Added</span> PTDocumentController.delegate</div>
<div class="difference"><span class="status added">Added</span> PTDocumentController.toolGroupsEnabled</div>
<div class="difference"><span class="status added">Added</span> PTDocumentController.toolGroupManager</div>
<div class="difference"><span class="status added">Added</span> PTDocumentController.toolGroupToolbar</div>
<div class="difference"><span class="status added">Added</span> PTDocumentController.toolGroupToolbarHidden</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentController setToolGroupToolbarHidden:animated:]</div>
<div class="difference"><span class="status added">Added</span> PTDocumentController.toolGroupIndicatorView</div>
<div class="difference"><span class="status added">Added</span> PTDocumentController.toolGroupViewController</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentController showToolGroups:]</div>
<div class="difference"><span class="status added">Added</span> PTDocumentController.navigationItem</div>
<div class="difference"><span class="status added">Added</span> PTDocumentController.tabsButtonItem</div>
<div class="difference"><span class="status added">Added</span> PTDocumentController.automaticallyShowsTabsButton</div>
<div class="difference"><span class="status added">Added</span> PTDocumentController.documentSliderViewController</div>
<div class="difference"><span class="status added">Added</span> PTDocumentController.documentSliderEnabled</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTDocumentNavigationItem.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTDocumentNavigationItem</div>
<div class="difference"><span class="status added">Added</span> PTDocumentNavigationItem.traitCollection</div>
<div class="difference"><span class="status added">Added</span> PTDocumentNavigationItem.leftBarButtonItems</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentNavigationItem setLeftBarButtonItems:animated:]</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentNavigationItem leftBarButtonItemsForSizeClass:]</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentNavigationItem setLeftBarButtonItems:forSizeClass:animated:]</div>
<div class="difference"><span class="status added">Added</span> PTDocumentNavigationItem.leftBarButtonItem</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentNavigationItem setLeftBarButtonItem:animated:]</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentNavigationItem leftBarButtonItemForSizeClass:]</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentNavigationItem setLeftBarButtonItem:forSizeClass:animated:]</div>
<div class="difference"><span class="status added">Added</span> PTDocumentNavigationItem.rightBarButtonItems</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentNavigationItem setRightBarButtonItems:animated:]</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentNavigationItem rightBarButtonItemsForSizeClass:]</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentNavigationItem setRightBarButtonItems:forSizeClass:animated:]</div>
<div class="difference"><span class="status added">Added</span> PTDocumentNavigationItem.rightBarButtonItem</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentNavigationItem setRightBarButtonItem:animated:]</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentNavigationItem rightBarButtonItemForSizeClass:]</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentNavigationItem setRightBarButtonItem:forSizeClass:animated:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTDocumentSlider.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTDocumentSlider</div>
<div class="difference"><span class="status added">Added</span> PTDocumentSlider.axis</div>
<div class="difference"><span class="status added">Added</span> PTDocumentSlider.thumbView</div>
<div class="difference"><span class="status added">Added</span> PTDocumentSlider.value</div>
<div class="difference"><span class="status added">Added</span> PTDocumentSlider.compress</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentSlider setValue:animated:]</div>
<div class="difference"><span class="status added">Added</span> PTDocumentSlider.minimumValue</div>
<div class="difference"><span class="status added">Added</span> PTDocumentSlider.maximumValue</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTDocumentSliderViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTDocumentSliderViewController</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentSliderViewController initWithPDFViewCtrl:]</div>
<div class="difference"><span class="status added">Added</span> PTDocumentSliderViewController.pdfViewCtrl</div>
<div class="difference"><span class="status added">Added</span> PTDocumentSliderViewController.slider</div>
<div class="difference"><span class="status added">Added</span> PTDocumentSliderViewController.sliderHidden</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentSliderViewController setSliderHidden:animated:]</div>
<div class="difference"><span class="status added">Added</span> PTDocumentSliderViewController.horizontalSliderInsets</div>
<div class="difference"><span class="status added">Added</span> PTDocumentSliderViewController.verticalSliderInsets</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTDocumentTabItem.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTDocumentTabItem</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentTabItem init]</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentTabItem initWithCoder:]</div>
<div class="difference"><span class="status added">Added</span> PTDocumentTabItem.sourceURL</div>
<div class="difference"><span class="status added">Added</span> PTDocumentTabItem.documentURL</div>
<div class="difference"><span class="status added">Added</span> PTDocumentTabItem.lastAccessedDate</div>
<div class="difference"><span class="status added">Added</span> PTDocumentTabItem.viewController</div>
<div class="difference"><span class="status added">Added</span> PTDocumentTabItem.headerView</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentTabItem setHeaderView:animated:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTDocumentTabManager.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTDocumentTabManager</div>
<div class="difference"><span class="status added">Added</span> PTDocumentTabManager.items</div>
<div class="difference"><span class="status added">Added</span> PTDocumentTabManager.selectedIndex</div>
<div class="difference"><span class="status added">Added</span> PTDocumentTabManager.selectedItem</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentTabManager addItem:]</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentTabManager insertItem:atIndex:]</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentTabManager removeItem:]</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentTabManager removeItemAtIndex:]</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentTabManager moveItemAtIndex:toIndex:]</div>
<div class="difference"><span class="status added">Added</span> PTDocumentTabManager.moving</div>
<div class="difference"><span class="status added">Added</span> PTDocumentTabManager.savedItemsURL</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentTabManager saveItems]</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentTabManager saveItemsToURL:]</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentTabManager restoreItems]</div>
<div class="difference"><span class="status added">Added</span> -[PTDocumentTabManager restoreItemsFromURL:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTDocumentViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status removed">Removed</span> -[PTDocumentViewController init]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTDocumentViewController openDocumentWithURL:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTDocumentViewController openDocumentWithURL:password:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTDocumentViewController openDocumentWithPDFDoc:]</div>
<div class="difference"><span class="status removed">Removed</span> PTDocumentViewController.httpRequestOptions</div>
<div class="difference"><span class="status removed">Removed</span> PTDocumentViewController.additionalHTTPHeaders</div>
<div class="difference"><span class="status removed">Removed</span> PTDocumentViewController.conversionOptions</div>
<div class="difference"><span class="status removed">Removed</span> PTDocumentViewController.document</div>
<div class="difference"><span class="status removed">Removed</span> PTDocumentViewController.coordinatedDocument</div>
<div class="difference"><span class="status removed">Removed</span> -[PTDocumentViewController saveDocument:completionHandler:]</div>
<div class="difference"><span class="status removed">Removed</span> PTDocumentViewController.pdfViewCtrl</div>
<div class="difference"><span class="status removed">Removed</span> PTDocumentViewController.toolManager</div>
<div class="difference"><span class="status removed">Removed</span> PTDocumentViewController.automaticallySavesDocument</div>
<div class="difference"><span class="status removed">Removed</span> PTDocumentViewController.automaticDocumentSavingInterval</div>
<div class="difference"><span class="status removed">Removed</span> -[PTDocumentViewController restartAutomaticDocumentSavingTimer]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTDocumentViewController restartAutomaticDocumentSavingTimerWithInterval:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTDocumentViewController stopAutomaticDocumentSavingTimer]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTDocumentViewController closeDocumentWithCompletionHandler:]</div>
<div class="difference"><span class="status removed">Removed</span> PTDocumentViewController.changesPageOnTap</div>
<div class="difference"><span class="status removed">Removed</span> PTDocumentViewController.nightModeEnabled</div>
<div class="difference"><span class="status removed">Removed</span> PTDocumentViewController.bottomToolbarEnabled</div>
<div class="difference"><span class="status removed">Removed</span> PTDocumentViewController.pageIndicatorEnabled</div>
<div class="difference"><span class="status removed">Removed</span> PTDocumentViewController.pageIndicatorShowsOnPageChange</div>
<div class="difference"><span class="status removed">Removed</span> PTDocumentViewController.pageIndicatorShowsWithControls</div>
<div class="difference"><span class="status removed">Removed</span> PTDocumentViewController.readerModeButtonHidden</div>
<div class="difference"><span class="status removed">Removed</span> PTDocumentViewController.viewerSettingsButtonHidden</div>
<div class="difference"><span class="status removed">Removed</span> PTDocumentViewController.shareButtonHidden</div>
<div class="difference"><span class="status removed">Removed</span> PTDocumentViewController.searchButtonHidden</div>
<div class="difference"><span class="status removed">Removed</span> PTDocumentViewController.exportButtonHidden</div>
<div class="difference"><span class="status removed">Removed</span> PTDocumentViewController.moreItemsButtonHidden</div>
<div class="difference"><span class="status removed">Removed</span> PTDocumentViewController.addPagesButtonHidden</div>
<div class="difference"><span class="status removed">Removed</span> PTDocumentViewController.thumbnailBrowserButtonHidden</div>
<div class="difference"><span class="status removed">Removed</span> PTDocumentViewController.navigationListsButtonHidden</div>
<div class="difference"><span class="status removed">Removed</span> PTDocumentViewController.annotationListHidden</div>
<div class="difference"><span class="status removed">Removed</span> PTDocumentViewController.outlineListHidden</div>
<div class="difference"><span class="status removed">Removed</span> PTDocumentViewController.bookmarkListHidden</div>
<div class="difference"><span class="status removed">Removed</span> PTDocumentViewController.pdfLayerListHidden</div>
<div class="difference"><span class="status removed">Removed</span> PTDocumentViewController.readerModeButtonItem</div>
<div class="difference"><span class="status removed">Removed</span> PTDocumentViewController.searchButtonItem</div>
<div class="difference"><span class="status removed">Removed</span> PTDocumentViewController.exportButtonItem</div>
<div class="difference"><span class="status removed">Removed</span> PTDocumentViewController.shareButtonItem</div>
<div class="difference"><span class="status removed">Removed</span> PTDocumentViewController.settingsButtonItem</div>
<div class="difference"><span class="status removed">Removed</span> PTDocumentViewController.thumbnailsButtonItem</div>
<div class="difference"><span class="status removed">Removed</span> PTDocumentViewController.navigationListsButtonItem</div>
<div class="difference"><span class="status removed">Removed</span> PTDocumentViewController.moreItemsButtonItem</div>
<div class="difference"><span class="status removed">Removed</span> PTDocumentViewController.addPagesButtonItem</div>
<div class="difference"><span class="status removed">Removed</span> PTDocumentViewController.activityViewController</div>
<div class="difference"><span class="status removed">Removed</span> PTDocumentViewController.navigationListsViewController</div>
<div class="difference"><span class="status removed">Removed</span> PTDocumentViewController.alwaysShowNavigationListsAsModal</div>
<div class="difference"><span class="status removed">Removed</span> -[PTDocumentViewController showNavigationLists]</div>
<div class="difference"><span class="status removed">Removed</span> PTDocumentViewController.thumbnailsViewController</div>
<div class="difference"><span class="status removed">Removed</span> -[PTDocumentViewController showThumbnailsController]</div>
<div class="difference"><span class="status removed">Removed</span> PTDocumentViewController.textSearchViewController</div>
<div class="difference"><span class="status removed">Removed</span> -[PTDocumentViewController showSearchViewController]</div>
<div class="difference"><span class="status removed">Removed</span> PTDocumentViewController.thumbnailSliderController</div>
<div class="difference"><span class="status removed">Removed</span> PTDocumentViewController.pageIndicatorViewController</div>
<div class="difference"><span class="status removed">Removed</span> PTDocumentViewController.settingsViewController</div>
<div class="difference"><span class="status removed">Removed</span> PTDocumentViewController.reflowViewController</div>
<div class="difference"><span class="status removed">Removed</span> PTDocumentViewController.moreItemsViewController</div>
<div class="difference"><span class="status removed">Removed</span> PTDocumentViewController.addPagesViewController</div>
<div class="difference"><span class="status removed">Removed</span> PTDocumentViewController.controlsHidden</div>
<div class="difference"><span class="status removed">Removed</span> -[PTDocumentViewController setControlsHidden:animated:]</div>
<div class="difference"><span class="status removed">Removed</span> PTDocumentViewController.searchViewControllerHidden</div>
<div class="difference"><span class="status removed">Removed</span> PTDocumentViewController.thumbnailSliderHidden</div>
<div class="difference"><span class="status removed">Removed</span> -[PTDocumentViewController setThumbnailSliderHidden:animated:]</div>
<div class="difference"><span class="status removed">Removed</span> PTDocumentViewController.pageIndicatorHidden</div>
<div class="difference"><span class="status removed">Removed</span> -[PTDocumentViewController setPageIndicatorHidden:animated:]</div>
<div class="difference"><span class="status removed">Removed</span> PTDocumentViewController.reflowHidden</div>
<div class="difference"><span class="status removed">Removed</span> PTDocumentViewController.hidesControlsOnTap</div>
<div class="difference"><span class="status removed">Removed</span> PTDocumentViewController.automaticallyHidesControls</div>
<div class="difference"><span class="status removed">Removed</span> PTDocumentViewController.automaticallyHideToolbars</div>
<div class="difference"><span class="status removed">Removed</span> PTDocumentViewController.automaticControlHidingDelay</div>
<div class="difference"><span class="status removed">Removed</span> -[PTDocumentViewController restartAutomaticControlHidingTimer]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTDocumentViewController restartAutomaticControlHidingTimerWithDelay:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTDocumentViewController stopAutomaticControlHidingTimer]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTDocumentViewController initWithCoder:]</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTDocumentViewController.undoButtonItem</div>
<div class="difference"><span class="status added">Added</span> PTDocumentViewController.redoButtonItem</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> PTDocumentViewController</div>
<table>
<tr><th></th><th>Superclass</th><th>Protocols</th></tr>
<tr><th>From</th><td>UIViewController</td><td>PTAnnotationToolbarDelegate, PTAnnotationViewControllerDelegate, PTBookmarkViewControllerDelegate, PTCoordinatedDocumentDelegate, PTDocumentViewSettingsControllerDelegate, PTOutlineViewControllerDelegate, PTOverridable, PTPDFLayerViewControllerDelegate, PTPDFViewCtrlDelegate, PTReflowViewControllerDelegate, PTTextSearchViewControllerDelegate, PTThumbnailSliderViewDelegate, PTToolManagerDelegate</td></tr>
<tr><th>To</th><td>PTDocumentBaseViewController</td><td>PTAnnotationToolbarDelegate, PTOverridable</td></tr>
</table>
<br>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTDocumentViewSettingsController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTDocumentViewSettingsController.doneButtonItem</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTEditToolbar.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> -[PTEditToolbar initWithToolManager:]</div>
<div class="difference"><span class="status added">Added</span> PTEditToolbar.toolManager</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTFreeHandCreate.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTFreeHandCreate.requiresEditSupport</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTFreeTextCreate.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> +[PTFreeTextCreate createAppearanceForAnnot:onDoc:withViewerRotation:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTHalfModalPresentationManager.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTHalfModalPresentationManager.popoverBarButtonItem</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTPDFViewCtrlAdditions.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTPagePresentationModeGetBaseMode()</div>
<div class="difference"><span class="status added">Added</span> PTPagePresentationModeIsContinuous()</div>
<div class="difference"><span class="status added">Added</span> PTPagePresentationModeGetEffectiveMode()</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTRubberStampCreate.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTStampType</div>
<div class="difference"><span class="status added">Added</span> PTStampTypeCheckMark</div>
<div class="difference"><span class="status added">Added</span> PTStampTypeCrossMark</div>
<div class="difference"><span class="status added">Added</span> PTStampTypeDot</div>
<div class="difference"><span class="status added">Added</span> PTRubberStampCreate</div>
<div class="difference"><span class="status added">Added</span> PTRubberStampCreate.stampType</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTRubberStampManager.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTRubberStampKeyText</div>
<div class="difference"><span class="status added">Added</span> PTRubberStampKeyTextBelow</div>
<div class="difference"><span class="status added">Added</span> PTRubberStampKeyFillColorStart</div>
<div class="difference"><span class="status added">Added</span> PTRubberStampKeyFillColorEnd</div>
<div class="difference"><span class="status added">Added</span> PTRubberStampKeyTextColor</div>
<div class="difference"><span class="status added">Added</span> PTRubberStampKeyBorderColor</div>
<div class="difference"><span class="status added">Added</span> PTRubberStampKeyFillOpacity</div>
<div class="difference"><span class="status added">Added</span> PTRubberStampKeyPointingLeft</div>
<div class="difference"><span class="status added">Added</span> PTRubberStampKeyPointingRight</div>
<div class="difference"><span class="status added">Added</span> PTRubberStampManager</div>
<div class="difference"><span class="status added">Added</span> PTRubberStampManager.lightRedStartColor</div>
<div class="difference"><span class="status added">Added</span> PTRubberStampManager.lightRedEndColor</div>
<div class="difference"><span class="status added">Added</span> PTRubberStampManager.lightRedTextColor</div>
<div class="difference"><span class="status added">Added</span> PTRubberStampManager.lightRedBorderColor</div>
<div class="difference"><span class="status added">Added</span> PTRubberStampManager.darkRedStartColor</div>
<div class="difference"><span class="status added">Added</span> PTRubberStampManager.darkRedEndColor</div>
<div class="difference"><span class="status added">Added</span> PTRubberStampManager.darkRedTextColor</div>
<div class="difference"><span class="status added">Added</span> PTRubberStampManager.darkRedBorderColor</div>
<div class="difference"><span class="status added">Added</span> PTRubberStampManager.lightGreenStartColor</div>
<div class="difference"><span class="status added">Added</span> PTRubberStampManager.lightGreenEndColor</div>
<div class="difference"><span class="status added">Added</span> PTRubberStampManager.lightGreenTextColor</div>
<div class="difference"><span class="status added">Added</span> PTRubberStampManager.lightGreenBorderColor</div>
<div class="difference"><span class="status added">Added</span> PTRubberStampManager.lightBlueStartColor</div>
<div class="difference"><span class="status added">Added</span> PTRubberStampManager.lightBlueEndColor</div>
<div class="difference"><span class="status added">Added</span> PTRubberStampManager.lightBlueTextColor</div>
<div class="difference"><span class="status added">Added</span> PTRubberStampManager.lightBlueBorderColor</div>
<div class="difference"><span class="status added">Added</span> PTRubberStampManager.yellowStartColor</div>
<div class="difference"><span class="status added">Added</span> PTRubberStampManager.yellowEndColor</div>
<div class="difference"><span class="status added">Added</span> PTRubberStampManager.yellowTextColor</div>
<div class="difference"><span class="status added">Added</span> PTRubberStampManager.yellowBorderColor</div>
<div class="difference"><span class="status added">Added</span> PTRubberStampManager.purpleStartColor</div>
<div class="difference"><span class="status added">Added</span> PTRubberStampManager.purpleEndColor</div>
<div class="difference"><span class="status added">Added</span> PTRubberStampManager.purpleTextColor</div>
<div class="difference"><span class="status added">Added</span> PTRubberStampManager.purpleBorderColor</div>
<div class="difference"><span class="status added">Added</span> -[PTRubberStampManager numberOfStandardStamps]</div>
<div class="difference"><span class="status added">Added</span> PTRubberStampManager.standardStampOptions</div>
<div class="difference"><span class="status added">Added</span> +[PTRubberStampManager getBitMapForStampWithHeight:width:option:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTRubberStampViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTRubberStampViewControllerDelegate</div>
<div class="difference"><span class="status added">Added</span> -[PTRubberStampViewControllerDelegate rubberStampController:addStamp:]</div>
<div class="difference"><span class="status added">Added</span> -[PTRubberStampViewControllerDelegate rubberStampControllerWasDismissed:]</div>
<div class="difference"><span class="status added">Added</span> PTRubberStampViewController</div>
<div class="difference"><span class="status added">Added</span> PTRubberStampViewController.collectionView</div>
<div class="difference"><span class="status added">Added</span> PTRubberStampViewController.editingEnabled</div>
<div class="difference"><span class="status added">Added</span> PTRubberStampViewController.editingSupported</div>
<div class="difference"><span class="status added">Added</span> PTRubberStampViewController.rubberStampManager</div>
<div class="difference"><span class="status added">Added</span> PTRubberStampViewController.delegate</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTSelectableBarButtonItem.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTSelectableBarButtonItem</div>
<div class="difference"><span class="status added">Added</span> PTSelectableBarButtonItem.selected</div>
<div class="difference"><span class="status added">Added</span> PTSelectableBarButtonItem.badgeHidden</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTTabbedDocumentViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> -[PTTabbedDocumentViewControllerDelegate tabbedDocumentViewController:createViewControllerForDocumentAtURL:]</div>
<div class="difference"><span class="status added">Added</span> -[PTTabbedDocumentViewControllerDelegate tabbedDocumentViewController:shouldHideTabBarForTraitCollection:]</div>
<div class="difference"><span class="status added">Added</span> PTTabbedDocumentViewController.tabManager</div>
<div class="difference"><span class="status added">Added</span> -[PTTabbedDocumentViewController removeTabForViewController:]</div>
<div class="difference"><span class="status added">Added</span> -[PTTabbedDocumentViewController showTabsList:]</div>
<div class="difference"><span class="status added">Added</span> PTTabbedDocumentViewController.viewControllerClass</div>
<div class="difference"><span class="status added">Added</span> -[PTTabbedDocumentViewController initWithCoder:]</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> -[PTTabbedDocumentViewControllerDelegate tabbedDocumentViewController:willAddDocumentViewController:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (void)tabbedDocumentViewController:(nonnull PTTabbedDocumentViewController *)tabbedDocumentViewController willAddDocumentViewController:(nonnull PTDocumentViewController *)documentViewController</td></tr>
<tr><th>To</th><td class="declaration">- (void)tabbedDocumentViewController:(nonnull PTTabbedDocumentViewController *)tabbedDocumentViewController willAddDocumentViewController:(nonnull __kindof PTDocumentBaseViewController *)documentViewController</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> PTTabbedDocumentViewController.tabURLs</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">@property (nonatomic, copy, nonnull) NSArray&lt;NSURL *&gt; *tabURLs</td></tr>
<tr><th>To</th><td class="declaration">@property (nonatomic, readonly, copy, nonnull) NSArray&lt;NSURL *&gt; *tabURLs</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTTabbedDocumentViewController documentViewControllerAtIndex:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (nullable PTDocumentViewController *)documentViewControllerAtIndex:(NSUInteger)index</td></tr>
<tr><th>To</th><td class="declaration">- (nullable __kindof PTDocumentBaseViewController *)documentViewControllerAtIndex:(NSUInteger)index</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> PTTabbedDocumentViewController.selectedURL</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">@property (nonatomic, weak, nullable) NSURL *selectedURL</td></tr>
<tr><th>To</th><td class="declaration">@property (nonatomic, readonly, weak, nullable) NSURL *selectedURL</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> PTTabbedDocumentViewController.selectedViewController</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">@property (nonatomic, readonly, nullable) PTDocumentViewController *selectedViewController</td></tr>
<tr><th>To</th><td class="declaration">@property (nonatomic, readonly, nullable) __kindof PTDocumentBaseViewController *selectedViewController</td></tr>
</table>
<br>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTTool.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status removed">Removed</span> -[PTTool viewController]</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTTool.image</div>
<div class="difference"><span class="status added">Added</span> PTTool.localizedName</div>
<div class="difference"><span class="status added">Added</span> PTTool.identifier</div>
<div class="difference"><span class="status added">Added</span> PTTool.canEditStyle</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> +[PTTool createsAnnotation]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">+ (BOOL)createsAnnotation</td></tr>
<tr><th>To</th><td class="declaration">@property (class, nonatomic, readonly) BOOL createsAnnotation</td></tr>
</table>
<br>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTToolBarButtonItem.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTToolBarButtonItem</div>
<div class="difference"><span class="status added">Added</span> -[PTToolBarButtonItem initWithToolClass:target:action:]</div>
<div class="difference"><span class="status added">Added</span> PTToolBarButtonItem.toolClass</div>
<div class="difference"><span class="status added">Added</span> PTToolBarButtonItem.identifier</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTToolGroup.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTToolGroup</div>
<div class="difference"><span class="status added">Added</span> -[PTToolGroup initWithTitle:barButtonItems:]</div>
<div class="difference"><span class="status added">Added</span> -[PTToolGroup initWithImage:barButtonItems:]</div>
<div class="difference"><span class="status added">Added</span> -[PTToolGroup initWithTitle:image:barButtonItems:]</div>
<div class="difference"><span class="status added">Added</span> -[PTToolGroup initWithCoder:]</div>
<div class="difference"><span class="status added">Added</span> +[PTToolGroup groupWithTitle:image:barButtonItems:]</div>
<div class="difference"><span class="status added">Added</span> PTToolGroup.title</div>
<div class="difference"><span class="status added">Added</span> PTToolGroup.image</div>
<div class="difference"><span class="status added">Added</span> PTToolGroup.barButtonItems</div>
<div class="difference"><span class="status added">Added</span> PTToolGroup.editable</div>
<div class="difference"><span class="status added">Added</span> PTToolGroup.favorite</div>
<div class="difference"><span class="status added">Added</span> PTToolGroup.identifier</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTToolGroupIndicatorView.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTToolGroupIndicatorView</div>
<div class="difference"><span class="status added">Added</span> PTToolGroupIndicatorView.button</div>
<div class="difference"><span class="status added">Added</span> PTToolGroupIndicatorView.toolGroupManager</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTToolGroupManager.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTToolGroupManagerDelegate</div>
<div class="difference"><span class="status added">Added</span> -[PTToolGroupManagerDelegate toolGroupManager:editItemsForGroup:]</div>
<div class="difference"><span class="status added">Added</span> PTToolGroupManager</div>
<div class="difference"><span class="status added">Added</span> -[PTToolGroupManager init]</div>
<div class="difference"><span class="status added">Added</span> -[PTToolGroupManager initWithCoder:]</div>
<div class="difference"><span class="status added">Added</span> -[PTToolGroupManager initWithToolManager:]</div>
<div class="difference"><span class="status added">Added</span> PTToolGroupManager.toolManager</div>
<div class="difference"><span class="status added">Added</span> PTToolGroupManager.delegate</div>
<div class="difference"><span class="status added">Added</span> PTToolGroupManager.enabled</div>
<div class="difference"><span class="status added">Added</span> PTToolGroupManager.groups</div>
<div class="difference"><span class="status added">Added</span> PTToolGroupManager.selectedGroup</div>
<div class="difference"><span class="status added">Added</span> PTToolGroupManager.selectedGroupIndex</div>
<div class="difference"><span class="status added">Added</span> PTToolGroupManager.viewItemGroup</div>
<div class="difference"><span class="status added">Added</span> PTToolGroupManager.annotateItemGroup</div>
<div class="difference"><span class="status added">Added</span> PTToolGroupManager.drawItemGroup</div>
<div class="difference"><span class="status added">Added</span> PTToolGroupManager.insertItemGroup</div>
<div class="difference"><span class="status added">Added</span> PTToolGroupManager.measureItemGroup</div>
<div class="difference"><span class="status added">Added</span> PTToolGroupManager.pensItemGroup</div>
<div class="difference"><span class="status added">Added</span> PTToolGroupManager.favoritesItemGroup</div>
<div class="difference"><span class="status added">Added</span> -[PTToolGroupManager createItemForToolClass:]</div>
<div class="difference"><span class="status added">Added</span> PTToolGroupManager.undoManager</div>
<div class="difference"><span class="status added">Added</span> PTToolGroupManager.undoButtonItem</div>
<div class="difference"><span class="status added">Added</span> -[PTToolGroupManager undo:]</div>
<div class="difference"><span class="status added">Added</span> PTToolGroupManager.redoButtonItem</div>
<div class="difference"><span class="status added">Added</span> -[PTToolGroupManager redo:]</div>
<div class="difference"><span class="status added">Added</span> PTToolGroupManager.addFavoriteToolButtonItem</div>
<div class="difference"><span class="status added">Added</span> -[PTToolGroupManager addFavoriteTool:]</div>
<div class="difference"><span class="status added">Added</span> PTToolGroupManager.editingEnabled</div>
<div class="difference"><span class="status added">Added</span> PTToolGroupManager.editGroupButtonItem</div>
<div class="difference"><span class="status added">Added</span> -[PTToolGroupManager editSelectedGroup:]</div>
<div class="difference"><span class="status added">Added</span> PTToolGroupManager.annotStylePresets</div>
<div class="difference"><span class="status added">Added</span> -[PTToolGroupManager saveGroups]</div>
<div class="difference"><span class="status added">Added</span> -[PTToolGroupManager saveGroupsToURL:]</div>
<div class="difference"><span class="status added">Added</span> -[PTToolGroupManager restoreGroups]</div>
<div class="difference"><span class="status added">Added</span> -[PTToolGroupManager restoreGroupsFromURL:]</div>
<div class="difference"><span class="status added">Added</span> PTToolGroupManager.savedGroupsURL</div>
<div class="difference"><span class="status added">Added</span> PTToolGroupDidChangeNotification</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTToolGroupToolbar.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTToolGroupToolbarDelegate</div>
<div class="difference"><span class="status added">Added</span> -[PTToolGroupToolbarDelegate viewControllerForPresentationsFromToolGroupToolbar:]</div>
<div class="difference"><span class="status added">Added</span> -[PTToolGroupToolbarDelegate viewForOverlaysFromToolGroupToolbar:]</div>
<div class="difference"><span class="status added">Added</span> PTToolGroupToolbar</div>
<div class="difference"><span class="status added">Added</span> -[PTToolGroupToolbar initWithToolGroupManager:]</div>
<div class="difference"><span class="status added">Added</span> PTToolGroupToolbar.delegate</div>
<div class="difference"><span class="status added">Added</span> PTToolGroupToolbar.toolGroupManager</div>
<div class="difference"><span class="status added">Added</span> PTToolGroupToolbar.items</div>
<div class="difference"><span class="status added">Added</span> PTToolGroupToolbar.leadingItems</div>
<div class="difference"><span class="status added">Added</span> PTToolGroupToolbar.trailingItems</div>
<div class="difference"><span class="status added">Added</span> PTToolGroupToolbar.itemTintColor</div>
<div class="difference"><span class="status added">Added</span> PTToolGroupToolbar.presetsToolbar</div>
<div class="difference"><span class="status added">Added</span> PTToolGroupToolbar.presetsToolbarEnabled</div>
<div class="difference"><span class="status added">Added</span> PTToolGroupToolbar.presetsToolbarHidden</div>
<div class="difference"><span class="status added">Added</span> -[PTToolGroupToolbar setPresetsToolbarHidden:animated:]</div>
<div class="difference"><span class="status added">Added</span> PTToolGroupToolbar.presetsView</div>
<div class="difference"><span class="status added">Added</span> PTToolGroupToolbar.presetsViewHidden</div>
<div class="difference"><span class="status added">Added</span> -[PTToolGroupToolbar setPresetsViewHidden:animated:]</div>
<div class="difference"><span class="status added">Added</span> PTToolGroupToolbar.stylePicker</div>
<div class="difference"><span class="status added">Added</span> PTToolGroupToolbar.editViewHidden</div>
<div class="difference"><span class="status added">Added</span> -[PTToolGroupToolbar setEditViewHidden:animated:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTToolGroupViewController.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTToolGroupViewController</div>
<div class="difference"><span class="status added">Added</span> PTToolGroupViewController.toolGroupManager</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTToolManager.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTToolManager.textEditingEnabled</div>
<div class="difference"><span class="status added">Added</span> -[PTToolManagerDelegate toolManager:willSwitchToTool:]</div>
<div class="difference"><span class="status added">Added</span> PTToolManagerToolWillChangeNotification</div>
<div class="difference"><span class="status added">Added</span> PTToolManagerToolUserInfoKey</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> -[PTAnnot GetUniqueIDAsString]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (nonnull NSString *)GetUniqueIDAsString</td></tr>
<tr><th>To</th><td class="declaration">- (nullable NSString *)GetUniqueIDAsString</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTFreeText getFontName]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (nonnull NSString *)getFontName</td></tr>
<tr><th>To</th><td class="declaration">- (nullable NSString *)getFontName</td></tr>
</table>
<br>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTToolsSettingsManager.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status removed">Removed</span> PTToolsSettingsManager.showThumbnailsInSlider</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTToolsUtil.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTToolsUtil.toolsResourcesDirectoryURL</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTUndoRedoManager.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> -[PTUndoRedoManager pageContentModifiedOnPageNumber:]</div>
</div>

</div>
</body>
</html>

`

export default (props) => {
  return (
    <Container className='api-dif'>
      <RawHTML html={html}/>
    </Container>
  )
}